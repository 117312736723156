.imagediv{
  position: relative;/*相対配置*/
  width: 100%;
  /* max-height: 400px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #faf4f4;
  /* ラベルとテキスト間の余白を指定する */
  margin: 0.4em 0px; 
}

.showimg {
  max-width: 400px;
  -ms-max-width: 400px;
  max-height: 400px;
  -ms-max-height: 400px;
  width: 100%;
  height: 100%;
  /* position: absolute; */
  margin: auto;
}

.imgOverlay {
  /*絶対配置*/
  max-width: 400px;
  -ms-max-width: 400px;
  max-height: 400px;
  -ms-max-height: 400px;
  width: 100%;
  height: 100%;
  position: absolute;
  margin: auto;
  top: 50%;  /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
}

.opacity {
  opacity: 0.6;
}
