@import url(https://fonts.googleapis.com/earlyaccess/roundedmplus1c.css);
@charset "UTF-8";
@font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 400;
    src: url(//fonts.gstatic.com/ea/notosansjapanese/v3/NotoSansJP-Regular.woff2) format('woff2'), url(//fonts.gstatic.com/ea/notosansjapanese/v3/NotoSansJP-Regular.woff) format('woff'), url(//fonts.gstatic.com/ea/notosansjapanese/v3/NotoSansJP-Regular.otf) format('opentype');
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Rounded Mplus 1c', sans-serif;
    /* font-family: "Comic Sans MS", Meiryo, "メイリオ", "Open Sans", "Helvetica Neue", Helvetica, Arial, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", sans-serif; */
    font-weight: 600;
    margin: 0px;
    /* margin-bottom: 0.4rem; */
}

.App {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.AppHeader {
  width: 100%;
  height: 50px;
  background: #404040;
  color: #FFCCCC;
  padding: 6px;
}

.AppBody{
  width: 100%;
 display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  background-color: #faf4f4;
  min-height: 100vh;
  font-size: calc(12px + 2vmin);
  color: rgb(22, 22, 22);
}

.btndiv{
  width: 100%;
  height: auto;
  /* margin: 4.0em 0em; */
  margin: auto 0em;
}

.footer{
  margin-top: auto;
  padding-top: 2em;
  font-size: 0.7em;
}

.AppHeader {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;  
  /* background-color: aqua; */
  background: #404040;
  color: #FFCCCC;
  padding: 6px 0px;
  font-size: 0.7em;
}

a {
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  font-family: 'Rounded Mplus 1c', sans-serif;
  /* font-family: "Comic Sans MS", Meiryo, "メイリオ", "Open Sans", "Helvetica Neue", Helvetica, Arial, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", sans-serif; */
  font-weight: 600;
  margin: 0px;
  color: #FFCCCC;
}
label {
  color: rgb(255, 255, 255); /* ラベルテキストの色を指定する */
  font-size: 0.8em; /* ラベルテキストのサイズを指定する */
  background-color: #006DD9;/* ラベルの背景色を指定する */
  padding: 0.4em;
  padding-top: 0.3em;
  margin: 0.4em; /* ラベルとテキスト間の余白を指定する */
  border-radius: 10px; /* ラベルボックスの角丸を指定する */
}

label > input {
   /* アップロードボタンのスタイルを無効にする */
  display:none;
}

.upgraded-button{
  padding: 5px 20px;
  border-radius: 5px;
  display: inline-block;
  border: 1px solid rgb(0, 0, 0);
  color: rgb(0, 0, 0);
  pointer-events: none;
}

.btn {
  font-size: 1.2em;
  padding: 0.2em 0.4em;
  -ms-padding: 0.2em 0.4em;
  -webkit-padding : 0.0em 0.4em;
  margin: 0.2em 0.4em;
  text-decoration: none;
  background-color: white;
  border-radius: 3px;
  -webkit-transition: .4s;
  transition: .4s;
}

.btn_disc {
  color: #eb7311;
  border: solid 2px #eb7311;
}

.btn_disc:hover {
  background: #eb7311;
  color: white;
}

.btn_judge {
  color: #2266fa;
  border: solid 2px #2266fa;
}

.btn_judge:hover {
  background: #2266fa;
  color: white;
}

.btn_cansel {
  color: #ff67c5;
  border: solid 2px #ff67c5;
}

.btn_cansel:hover {
  background: #ff67c5;
  color: white;
}

.btn_calc {
  background: white;
  color: #068621;
  border: solid 2px #068621;
}

.btn_calc:hover {
  background: #068621;
  color: white;
}

.btn_done {
  background: #6e6e6e;
  color: white;
  border: solid 2px #6e6e6e;
}

.btn_margin {
  margin: 0.1em 0.2em;
}
.ResultDiv {
  /* max-width: 600px; */
  width: 100%;
  font-size: 0.8em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin: 0px auto; */
}

.ResultMsg{
  font-size: 0.85em;
}

.resTitle {
  color: #046611;
  font-weight: 550;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.MsgArea {
  justify-content: flex-end;
  color: rgb(39, 47, 161);
  background: #FFF;
  border: solid 3px #6091d3;
  border-radius: 8px 24px;
  font-weight: bold;
  width: 80%;
  max-width: 800px;
  margin: 1em auto;
  margin-bottom: 12px;
  padding: 0.6em 1em;
}

.costArea {
  color: #222222;
  font-weight: 600;
  font-size: 0.9em;
}
.GoogleAdSense {
  /* background-color: blue; */
  min-width: 100%;
  min-height: 50px;
}

.imagediv{
  position: relative;/*相対配置*/
  width: 100%;
  /* max-height: 400px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #faf4f4;
  /* ラベルとテキスト間の余白を指定する */
  margin: 0.4em 0px; 
}

.showimg {
  max-width: 400px;
  -ms-max-width: 400px;
  max-height: 400px;
  -ms-max-height: 400px;
  width: 100%;
  height: 100%;
  /* position: absolute; */
  margin: auto;
}

.imgOverlay {
  /*絶対配置*/
  max-width: 400px;
  -ms-max-width: 400px;
  max-height: 400px;
  -ms-max-height: 400px;
  width: 100%;
  height: 100%;
  position: absolute;
  margin: auto;
  top: 50%;  /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
}

.opacity {
  opacity: 0.6;
}

.SNSArea {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 1em 0em;
}

.space {
  margin: 0em 0.1em;
}

a {
  display:block;
}

a.btn.btn_disc.thisbtn{
  font-size: 1.2rem;
  margin-left: 1rem;
}

body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-family: 'Noto Sans Japanese', "Meiryo", "\30E1\30A4\30EA\30AA", sans-serif;;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root{
  width: 100%;
  height: 100%;
}
