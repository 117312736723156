.AppHeader {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;  
  /* background-color: aqua; */
  background: #404040;
  color: #FFCCCC;
  padding: 6px 0px;
  font-size: 0.7em;
}

a {
  text-decoration-line: none;
  font-family: 'Rounded Mplus 1c', sans-serif;
  /* font-family: "Comic Sans MS", Meiryo, "メイリオ", "Open Sans", "Helvetica Neue", Helvetica, Arial, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", sans-serif; */
  font-weight: 600;
  margin: 0px;
  color: #FFCCCC;
}