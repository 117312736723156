body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-family: 'Noto Sans Japanese', "Meiryo", "メイリオ", sans-serif;;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root{
  width: 100%;
  height: 100%;
}