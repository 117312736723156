@charset "UTF-8";
@font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 400;
    src: url(//fonts.gstatic.com/ea/notosansjapanese/v3/NotoSansJP-Regular.woff2) format('woff2'), url(//fonts.gstatic.com/ea/notosansjapanese/v3/NotoSansJP-Regular.woff) format('woff'), url(//fonts.gstatic.com/ea/notosansjapanese/v3/NotoSansJP-Regular.otf) format('opentype');
}

@import url(https://fonts.googleapis.com/earlyaccess/roundedmplus1c.css);
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Rounded Mplus 1c', sans-serif;
    /* font-family: "Comic Sans MS", Meiryo, "メイリオ", "Open Sans", "Helvetica Neue", Helvetica, Arial, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", sans-serif; */
    font-weight: 600;
    margin: 0px;
    /* margin-bottom: 0.4rem; */
}

.App {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.AppHeader {
  width: 100%;
  height: 50px;
  background: #404040;
  color: #FFCCCC;
  padding: 6px;
}

.AppBody{
  width: 100%;
 display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  background-color: #faf4f4;
  min-height: 100vh;
  font-size: calc(12px + 2vmin);
  color: rgb(22, 22, 22);
}

.btndiv{
  width: 100%;
  height: auto;
  /* margin: 4.0em 0em; */
  margin: auto 0em;
}

.footer{
  margin-top: auto;
  padding-top: 2em;
  font-size: 0.7em;
}
