label {
  color: rgb(255, 255, 255); /* ラベルテキストの色を指定する */
  font-size: 0.8em; /* ラベルテキストのサイズを指定する */
  background-color: #006DD9;/* ラベルの背景色を指定する */
  padding: 0.4em;
  padding-top: 0.3em;
  margin: 0.4em; /* ラベルとテキスト間の余白を指定する */
  border-radius: 10px; /* ラベルボックスの角丸を指定する */
}

label > input {
   /* アップロードボタンのスタイルを無効にする */
  display:none;
}

.upgraded-button{
  padding: 5px 20px;
  border-radius: 5px;
  display: inline-block;
  border: 1px solid rgb(0, 0, 0);
  color: rgb(0, 0, 0);
  pointer-events: none;
}

.btn {
  font-size: 1.2em;
  padding: 0.2em 0.4em;
  -ms-padding: 0.2em 0.4em;
  -webkit-padding : 0.0em 0.4em;
  margin: 0.2em 0.4em;
  text-decoration: none;
  background-color: white;
  border-radius: 3px;
  transition: .4s;
}

.btn_disc {
  color: #eb7311;
  border: solid 2px #eb7311;
}

.btn_disc:hover {
  background: #eb7311;
  color: white;
}

.btn_judge {
  color: #2266fa;
  border: solid 2px #2266fa;
}

.btn_judge:hover {
  background: #2266fa;
  color: white;
}

.btn_cansel {
  color: #ff67c5;
  border: solid 2px #ff67c5;
}

.btn_cansel:hover {
  background: #ff67c5;
  color: white;
}

.btn_calc {
  background: white;
  color: #068621;
  border: solid 2px #068621;
}

.btn_calc:hover {
  background: #068621;
  color: white;
}

.btn_done {
  background: #6e6e6e;
  color: white;
  border: solid 2px #6e6e6e;
}

.btn_margin {
  margin: 0.1em 0.2em;
}