.SNSArea {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 1em 0em;
}

.space {
  margin: 0em 0.1em;
}

a {
  display:block;
}

a.btn.btn_disc.thisbtn{
  font-size: 1.2rem;
  margin-left: 1rem;
}
