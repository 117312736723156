.ResultDiv {
  /* max-width: 600px; */
  width: 100%;
  font-size: 0.8em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin: 0px auto; */
}

.ResultMsg{
  font-size: 0.85em;
}

.resTitle {
  color: #046611;
  font-weight: 550;
  text-decoration-line: underline;
}

.MsgArea {
  justify-content: flex-end;
  color: rgb(39, 47, 161);
  background: #FFF;
  border: solid 3px #6091d3;
  border-radius: 8px 24px;
  font-weight: bold;
  width: 80%;
  max-width: 800px;
  margin: 1em auto;
  margin-bottom: 12px;
  padding: 0.6em 1em;
}

.costArea {
  color: #222222;
  font-weight: 600;
  font-size: 0.9em;
}